import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AddressFields from '~/components/address-fields.vue';
import ContactFields from '~/components/contact-fields.vue';
import CreditsCard from '~/credits/credits-card.vue';
import { mdiEmail } from '@mdi/js';
export default {
    name: 'service-fields',
    components: {
        ValidationObserver,
        ValidationProvider,
        AddressFields,
        ContactFields,
        CreditsCard,
    },
    model: {
        prop: 'service',
    },
    props: {
        service: {
            type: Object,
        },
        usedCreditsByType: {
            type: Object,
            default() { return {}; },
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            mdiEmail,
        };
    },
};
