import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import { routeNames } from '~/router';
import FormMixin from '~/mixins/form';
import PermissionMixin from '~/mixins/permission';
import CreditForItemMixin from '~/mixins/creditForItem';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import ServiceFields from '~/services/service-fields.vue';
import { ValidationObserver } from 'vee-validate';
import { mdiContentSaveOutline, mdiPlusCircleOutline, mdiViewList } from '@mdi/js';
const servicesλ = λ.namespaced('services');
export default {
    name: 'services-form',
    mixins: [FormMixin('services'), CreditForItemMixin('service'), PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        ServiceFields,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            mdiContentSaveOutline,
            mdiPlusCircleOutline,
            mdiViewList,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: servicesλ.state('editing'),
        canList: λ.self('canListServices'),
        hasId() {
            return Boolean(getId(this.item));
        },
        canSave() {
            return this.canUpdateServices || this.canUpdateOwnService(this.id);
        },
        canAddParticipation() {
            return this.canCreateParticipations;
        },
    },
    methods: {
        addNewParticipation() {
            this.$router.push({
                name: routeNames.participations.create,
                query: { service: getId(this.item) },
                params: { goBackTo: { name: routeNames.services.edit, params: { id: getId(this.item) } } },
            });
        },
        goToParticipationsList() {
            this.$router.push({
                name: routeNames.participations.list,
                query: { service: getId(this.item) },
                params: { goBackTo: { name: routeNames.services.edit, params: { id: getId(this.item) } } },
            });
        },
    },
};
