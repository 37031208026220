var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.service)?_c('div',{staticClass:"service-fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',{staticClass:"pb-10"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('General'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{ref:"reference",attrs:{"name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"reference","label":_vm.$t('Reference'),"error-messages":errors,"disabled":_vm.readOnly,"hide-details":"","outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.service.reference),callback:function ($$v) {_vm.$set(_vm.service, "reference", $$v)},expression:"service.reference"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":_vm.$t('services.approvalId'),"disabled":_vm.readOnly,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.service.approvalsNumber),callback:function ($$v) {_vm.$set(_vm.service, "approvalsNumber", $$v)},expression:"service.approvalsNumber"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"name",attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":_vm.$t('Name'),"error-messages":errors,"disabled":_vm.readOnly,"hide-details":"","outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"email",attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('E-mail'),"prepend-inner-icon":_vm.mdiEmail,"error-messages":errors,"disabled":_vm.readOnly,"autocapitalize":"none","autocomplete":"off","hide-details":"","outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.service.email),callback:function ($$v) {_vm.$set(_vm.service, "email", $$v)},expression:"service.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('Notes'),"disabled":_vm.readOnly,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.service.notes),callback:function ($$v) {_vm.$set(_vm.service, "notes", $$v)},expression:"service.notes"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('address-fields',{attrs:{"read-only":_vm.readOnly},model:{value:(_vm.service.address),callback:function ($$v) {_vm.$set(_vm.service, "address", $$v)},expression:"service.address"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('contact-fields',{attrs:{"read-only":_vm.readOnly},model:{value:(_vm.service.contact),callback:function ($$v) {_vm.$set(_vm.service, "contact", $$v)},expression:"service.contact"}})],1)],1)],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('credits-card',{attrs:{"credits":_vm.service.credits,"usedByType":_vm.usedCreditsByType,"read-only":_vm.readOnly,"flat":""}})],1)],1)]}}],null,false,3461465243)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }